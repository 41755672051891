import React, { useState } from "react";
import "animate.css"
import "./team.css";
import { MdCancel } from "react-icons/md";
import { motion } from "framer-motion";

const Team = () => {

    const [showCurric, setCurric] = useState(false);


    return (
        <>
            <div className="team_headline_container">
                <div className="team_headline">
                    <h1>
                        Unser Team
                    </h1>
                </div>
            </div>

            {showCurric ?
                <div className="team_container_doc">
                    <div className="team__container_doc">
                        <motion.div className="doc_curric" id="curr"
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                                duration: 0.5,
                                ease: [0, 0.71, 0.2, 1.01],
                                scale: {
                                    type: "spring",
                                    damping: 9,
                                    stiffness: 100,
                                    restDelta: 0.001
                                }
                            }}>
                            <div className="doc_curric__container">
                                <div className="doc_curric__headline">
                                    <h2>
                                        Dr. Christine Medefindt - Lebenslauf
                                    </h2>
                                </div>

                                <div className="doc_curric__content">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th style={{ width: "24%" }}>
                                                    &nbsp;
                                                </th>
                                                <th>
                                                    &nbsp;
                                                </th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>ab 2024</span>
                                                </td>
                                                <td>
                                                    Niederlassung in eigener kieferorthopädischer Fachzahnarztpraxis
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>
                                                        2020 - 2024
                                                    </span>
                                                </td>
                                                <td>
                                                    angestellte Fachzahnärztin für Kieferorthopädie
                                                    in einer niedergelassenen kieferorthopädischen Praxis
                                                </td>
                                            </tr>
                                            <tr>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>
                                                        2020
                                                    </span>
                                                </td>
                                                <td>
                                                    Annerkennung zur Fachzahnärztin für Kieferorthopädie
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>
                                                        2018 - 2020
                                                    </span>
                                                </td>
                                                <td>
                                                    Weiterbildung zur Fachzahnärztin für Kieferorthopädie an der Universitätsmedizin Göttingen
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>
                                                        2017 - 2018
                                                    </span>
                                                </td>
                                                <td>
                                                    Weiterbildung zur Fachzahnärztin für Kieferorthopädie in der Sternklink in Bremen
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>
                                                        2016 - 2017
                                                    </span>
                                                </td>
                                                <td>
                                                    Assistenszzahnärztin in einer niedergelassenen Zahnarztpraxis
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>
                                                        2014 - 2017
                                                    </span>
                                                </td>
                                                <td>
                                                    Promotion zum Dr. med. dent. an der Philipps-Universität in Marburg
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>
                                                        2016
                                                    </span>
                                                </td>
                                                <td>
                                                    Staatsexamen, Approbation zur Zahnärztin
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="button_container_close">
                                <button className="btn_close btn-bg-slide" onClick={() => setCurric((prev) => !prev)}>
                                    <MdCancel className="cancel_btn" />
                                </button>
                            </div>
                        </motion.div>
                        <div className="team__container_ZFA_img">
                            <div className="doc_name_container">
                                <h2 className="doc_name">
                                    <span className="doc__name">
                                        <p>
                                            Kim P. <br></br>
                                        </p>
                                    </span>
                                    ZAHNMEDIZINISCHE FACHANGESTELLTE
                                </h2>
                            </div>
                        </div>
                        <div className="team__container_ZFA_img">
                            <div className="doc_name_container">
                                <h2 className="doc_name">
                                    <span className="doc__name">
                                        <p>
                                            Ina H. <br></br>
                                        </p>
                                    </span>
                                    ZAHNMEDIZINISCHE FACHANGESTELLTE
                                </h2>
                            </div>
                        </div>
                        <div className="team__container_ZFA_img">
                            <div className="doc_name_container">
                                <h2 className="doc_name">
                                    <span className="doc__name">
                                        <p>
                                            Carmen C. <br></br>
                                        </p>
                                    </span>
                                    ZAHNMEDIZINISCHE FACHANGESTELLTE
                                </h2>
                            </div>
                        </div>
                        <div className="team__container_ZFA_img">
                            <div className="doc_name_container">
                                <h2 className="doc_name">
                                    <span className="doc__name">
                                        <p>
                                            Zahntechniker/in <br></br>
                                        </p>
                                    </span>
                                    Wir suchen Sie!
                                </h2>
                            </div>
                        </div>

                    </div>
                    <div className="inv_curric">
                    </div>
                </div>
                :
                <div className="team_container_doc">
                    <div className="team__container_doc">
                        <div className="team__container_doc_img">
                            <div className="button_container">
                                <button className="btn btn-bg-slide" onClick={() => setCurric((prev) => !prev)}>
                                    Lebenslauf
                                </button>
                            </div>
                            <div className="doc_name_container">
                                <h2 className="doc_name">
                                    <span className="doc__name">
                                        <p>
                                            Dr. Christine Medefindt <br></br>
                                        </p>
                                    </span>
                                    FACHZAHNÄRZTIN FÜR KIEFERORTHOPÄDIE
                                </h2>
                            </div>
                        </div>
                        <div className="team__container_ZFA_img">
                            <div className="doc_name_container">
                                <h2 className="doc_name">
                                    <span className="doc__name">
                                        <p>
                                            Kim P. <br></br>
                                        </p>
                                    </span>
                                    ZAHNMEDIZINISCHE FACHANGESTELLTE
                                </h2>
                            </div>
                        </div>
                        <div className="team__container_ZFA_img">
                            <div className="doc_name_container">
                                <h2 className="doc_name">
                                    <span className="doc__name">
                                        <p>
                                            Ina H. <br></br>
                                        </p>
                                    </span>
                                    ZAHNMEDIZINISCHE FACHANGESTELLTE
                                </h2>
                            </div>
                        </div>
                        <div className="team__container_ZFA_img">
                            <div className="doc_name_container">
                                <h2 className="doc_name">
                                    <span className="doc__name">
                                        <p>
                                            Carmen C. <br></br>
                                        </p>
                                    </span>
                                    ZAHNMEDIZINISCHE FACHANGESTELLTE
                                </h2>
                            </div>
                        </div>
                        <div className="team__container_ZFA_img">
                            <div className="doc_name_container">
                                <h2 className="doc_name">
                                    <span className="doc__name">
                                        <p>
                                            Zahntechniker/in <br></br>
                                        </p>
                                    </span>
                                    Wir suchen Sie!
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="genContainerFooter">
                <div className="genContainerFooter_inner">
                    <p>
                        Ihre Ansprechpartner rund um die Zahnkorrektur
                    </p>
                </div>
            </div>
        </>
    )
}

export default Team